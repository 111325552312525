import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DatePickerOnDateSelectEvent } from 'app/shared/components/common/date-picker/date-picker.component';
import moment, { Moment } from 'moment';
import * as _ from 'lodash';
import { IAccount } from 'app/shared/model/account.model';
import { PaymentProviderLabelPipe } from 'app/shared/pipes/payment-provider-label.pipe';
import { IRegion } from 'app/shared/model/region.model';
import { RegionApi } from 'app/shared/dataservices/region.api';
import { finalize } from 'rxjs/operators';

import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AccountService } from 'app/core/auth/account.service';
import {
    UnsubscribeCompletedModalService
} from 'app/account/settings/components/unsubscribe-completed-modal/unsubscribe-completed-modal.service';
import { ConfirmModalService } from 'app/shared/components/common/confirm-modal/confirm-modal.service';
import { BUILD_PARTNER_SUBSCRIBE_URL } from 'app/shared/constants/links.constants';
import { UnsubscriptionService } from 'app/shared/dataservices/unsubscription.service';
import { IUnsubscribeRequest, IUnsubscriptionReason } from 'app/shared/model/bp.model';
import { lastValueFrom } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { SubscriptionApi } from 'app/shared/dataservices/subscription.api';

const DATE_FORMAT = 'YYYY-MM-DD';

@Component({
    selector: 'bp-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['settings.scss']
})
export class SettingsComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    protected error: string;
    protected success: string;

    protected account: IAccount;

    protected regions: IRegion[];
    protected insuranceExpiryDate: Moment;
    protected incorporationDate: Moment;
    protected inProcessLoadRegions = false;

    protected proxyAdmin = false;

    @ViewChild('logo')
    protected logo: ElementRef;

    protected form: FormGroup;

    protected inProcess = false;

    constructor(
        private formBuilder: FormBuilder,
        protected accountService: AccountService,
        private changeDetector: ChangeDetectorRef,
        private paymentProviderLabelPipe: PaymentProviderLabelPipe,
        private regionService: RegionApi,
        private alertService: BpAlertService,
        private router: Router,
        private confirmModalService: ConfirmModalService,
        private unsubscribeCompletedModalService: UnsubscribeCompletedModalService,
        private unsubscriptionService: UnsubscriptionService,
        private subscriptionApi: SubscriptionApi,
        private localStorage: LocalStorageService
    ) {
    }

    ngOnInit(): void {
        this.proxyAdmin = this.localStorage.retrieve('proxyAdmin');

        this.reloadAccount().then(() => {
            this.form = this.formBuilder.group({
                firstName: new FormControl(this.account.firstName, [Validators.required, Validators.maxLength(50)]),
                lastName: new FormControl(this.account.lastName, [Validators.required, Validators.maxLength(50)]),
                email: new FormControl(this.account.email, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
                website: new FormControl(this.account.website),
                company: new FormControl(this.account.company),
                companyAddress: new FormControl(this.account.companyAddress),
                companyPostcode: new FormControl(this.account.companyPostcode),
                companyNumber: new FormControl(this.account.companyNumber),
                phoneNumber: new FormControl(this.account.phoneNumber),
                insuranceAmount: new FormControl(this.account.insuranceAmount),
                linkedQuoter: new FormControl(this.account.linkedQuoters?.length && this.account.linkedQuoters[0], [Validators.minLength(5), Validators.maxLength(100)]),
                region: new FormControl(this.account.region),
                replaceBPLogo: new FormControl(this.account.replaceBPLogo || false, [Validators.required]),
            });

            this.updateLogo();
        });

        this.loadRegions();
    }

    public save(): void {
        if (!this.form) {
            return;
        }

        this.form.markAllAsTouched();

        if (this.form.invalid) {
            this.alertService.warning('Please check that the form fields are filled in correctly');
            return;
        }

        const save = () => {
            this.blockUI.start('Please wait...');

            this.accountService.save(this.account).subscribe(
                () => {
                    //this.alertService.success('Save successful');

                    this.accountService.identity(true).then(account => {
                        this.setSettingsAccount(account);
                        this.updateLogo();
                        this.blockUI.stop();
                        this.router.navigate(['']);
                    });
                },
                () => {
                    this.blockUI.stop();
                    this.alertService.error('Settings could not be saved');
                }
            );
        };

        this.account.firstName = this.form.get('firstName').value;
        this.account.lastName = this.form.get('lastName').value;
        this.account.email = this.form.get('email').value;
        this.account.website = this.form.get('website').value;
        this.account.company = this.form.get('company').value;
        this.account.companyNumber = this.form.get('companyNumber').value;
        this.account.companyAddress = this.form.get('companyAddress').value;
        this.account.companyPostcode = this.form.get('companyPostcode').value;
        this.account.phoneNumber = this.form.get('phoneNumber').value;
        this.account.insuranceAmount = this.form.get('insuranceAmount').value;
        if (this.form.get('linkedQuoter').value?.length) {
            this.account.linkedQuoters = this.account.linkedQuoters || [''];
            this.account.linkedQuoters[0] = this.form.get('linkedQuoter').value;
        } else {
            this.account.linkedQuoters = null;
        }

        this.account.region = this.form.get('region').value;
        this.account.replaceBPLogo = this.form.get('replaceBPLogo').value;

        save();
    }

    protected copyAccount(account): IAccount {
        return Object.assign({}, account);
    }

    protected onImageUploaded(imgUrl: string | null): void {
        this.account.imageUrl = imgUrl;
        this.accountService.authenticate(this.account);
        this.updateLogo();
    }

    protected isQuoterOnly(): boolean {
        return this.accountService.isQuoter() && !this.accountService.isAdmin();
    }

    protected isSchedulerOnly(): boolean {
        return this.accountService.isScheduler() && !this.accountService.isAdmin();
    }

    protected onInsuranceExpiryDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.insuranceExpiryDate = event.newDate;
        this.account.insuranceExpiry = event.newDate.format(DATE_FORMAT);
    }

    protected onIncorporationDateSelect(event: DatePickerOnDateSelectEvent): void {
        this.incorporationDate = event.newDate;
        this.account.incorporationDate = event.newDate.format(DATE_FORMAT);
    }

    protected schedulerPaymentMethods(): string {
        return this.account.paymentProviders && this.account.paymentProviders.length > 0
            ? _.map(this.account.paymentProviders, p => this.paymentProviderLabelPipe.transform(p)).join(', ')
            : '-';
    }

    protected unsubscribe(): void {
        this.confirmModalService.open(
            {
                header: 'Unsubscribe from BuildPartner?',
                textHtml: `<div class='strong'>
                                By unsubscribing you will lose access to your BuildPartner account and projects.
                           </div>`,
                cancelButtonText: 'Close',
                confirmButtonText: 'Understood',
                swapButtons: true
            }
        ).result.then((res) => {
            if (res) {
                this.reloadAccount().then(() => {
                    this.unsubscribeCompletedModalService.open().result.then((res: {
                        reason: IUnsubscriptionReason,
                        comment: string
                    }) => {
                        const unsubscribeRequest: IUnsubscribeRequest = {
                            user_id: this.account.id,
                            comment: res.comment,
                            unsubscriptionReason: res.reason
                        }

                        this.blockUI.start('Please wait..');
                        lastValueFrom(this.unsubscriptionService.unsubscribe(unsubscribeRequest)).then(() => {
                            this.reloadAccount().then(() => {
                                this.alertService.success('You successfully unsubscribed!')
                            }).finally(() => {
                                this.blockUI.stop();
                            })
                        }, reason => this.blockUI.stop());
                    });
                });
            }
        })
    }

    protected pause(): void {
        this.confirmModalService.open(
            {
                header: 'Pause Your Subscription?',
                textHtml: `<div class='font-weight-normal'>
                               By pausing your subscription, you will temporarily lose access to your BuildPartner account
                               and projects. During this pause, you will <span class='strong'>not be charged</span>,
                               and you can resume your subscription at any time.
                           </div>`,
                cancelButtonText: 'Close',
                confirmButtonText: 'Understood',
                swapButtons: true,
                dropdownValues: [{id: 1, value: '1 month'}, {id: 2, value: '2 months'}, {id: 3, value: '3 months'}]
            }
        ).result.then((res: any) => {
            if (res) {
                this.blockUI.start('Please wait..');
                lastValueFrom(this.subscriptionApi.pause(this.account.id, res.dropdownValue.id)).then(() => {
                    this.reloadAccount().then(() => {
                        this.alertService.success('You successfully paused!')
                    }).finally(() => {
                        this.blockUI.stop();
                    })
                }, reason => this.blockUI.stop());
            }
        })
    }

    protected onSubscribeClick(): void {
        window.open(BUILD_PARTNER_SUBSCRIBE_URL, '_blank');
    }

    protected clearStorage(): void {
        const authenticationToken = this.localStorage.retrieve('authenticationToken');
        const proxyAdmin = this.localStorage.retrieve('proxyAdmin');
        this.localStorage.clear();
        this.localStorage.store('authenticationToken', authenticationToken);
        this.localStorage.store('proxyAdmin', proxyAdmin);
        this.alertService.success('Local Storage was successfully cleared');
    }

    private loadRegions(): void {
        this.inProcessLoadRegions = true;

        this.regionService
            .query()
            .pipe(
                finalize(() => {
                    this.inProcessLoadRegions = false;
                })
            )
            .subscribe(
                (res: HttpResponse<IRegion[]>) => {
                    this.regions = res.body;
                }
            );
    }

    private setSettingsAccount(account: IAccount): void {
        this.account = this.copyAccount(account);
        this.insuranceExpiryDate = this.account.insuranceExpiry != null ? moment(this.account.insuranceExpiry, DATE_FORMAT) : null;
        this.incorporationDate = this.account.incorporationDate != null ? moment(this.account.incorporationDate, DATE_FORMAT) : null;
    }

    private updateLogo(): void {
        this.changeDetector.detectChanges();
        if (this.account.imageUrl) {
            this.logo.nativeElement.style.backgroundImage = `url(${this.account.imageUrl})`;
        } else {
            this.logo.nativeElement.style.backgroundImage = null;
        }
    }

    private reloadAccount(): Promise<void> {
        return this.accountService.identity(true).then((account: IAccount) => {
            this.setSettingsAccount(account);
        });
    }
}
