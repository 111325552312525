import { Injectable } from '@angular/core';
import { IProject } from 'app/shared/model/project.model';
import { HttpResponse } from '@angular/common/http';
import { ProjectApi } from 'app/shared/dataservices/project.api';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { IComparison, IComparisonQoter } from 'app/shared/model/comparison.model';
import * as _ from 'lodash';
import {
    ICostPlanValueColumn
} from 'app/shared/components/projects/project-details-cost-visualization/cost-plan.service';
import { IsSchedulerProjectReadOnlyService } from 'app/shared/services/is-scheduler-project-read-only.service';
import { MainFilterInitialStateStorageService } from 'app/shared/services/main-filter-initial-state-storage.service';
import { IResourceCost } from 'app/shared/model/bp.model';
import { StatQuoterService } from 'app/shared/dataservices/stat-quoter.service';
import { ScheduleAreasHelperService } from 'app/shared/services/schedule-areas-helper.service';
import {
    getActualAreaIds,
    getActualStageIds,
    IMainViewFilterItemItem
} from 'app/shared/components/common/main-view-filter/main-view-filter.component';
import { from, lastValueFrom, Observable, Subject, Subscription, throwError, timer } from 'rxjs';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import { IInvitation } from 'app/shared/model/invitation.model';
import { catchError, concatMap, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { ApplicationStateService } from "app/core/application-state.service";

@Injectable({ providedIn: 'root' })
export class DashboardService {

    private _stopInvitationPolling$: Subject<boolean> = new Subject<boolean>();
    private _inProgressStatusCount = 0;

    constructor(
        private appStateService: ApplicationStateService,
        private dashboardStore: DashboardStore,
        private isSchedulerProjectReadOnlyService: IsSchedulerProjectReadOnlyService,
        private mainFilterInitialStateStorageService: MainFilterInitialStateStorageService,
        private scheduleAreasHelperService: ScheduleAreasHelperService,
        private statQuoterService: StatQuoterService,
        private invitationService: InvitationService,
        private alertService: BpAlertService,
        private projectApi: ProjectApi) {
    }

    public checkInvitations(): Promise<boolean> {
        let stopped = false;
        let subscription: Subscription;
        let attemptCount = 0;

        const stopPolling = () => {
            stopped = true;
            subscription?.unsubscribe();
        };

        return new Promise<boolean>((resolve, reject) => {
            const poll = () => {
                subscription = this.invitationService.query(this.appStateService.project.id)
                    .pipe(
                        catchError(error => {
                            console.error('Error during invitation check:', error);
                            stopPolling();
                            return throwError(() => new Error('Invitation check failed'));
                        }),
                        filter(res => {
                            if (!res) { // Check if res is actually defined
                                console.error('Invalid response from invitation service.');
                                stopPolling();
                                reject(new Error('Invalid response from invitation service'));
                                return false; // Prevent further processing
                            }
                            const inPCount = res.body.filter(invitation => invitation.status !== 'NOT_INVITED' && `${invitation.majorVersion}.${invitation.minorVersion}` !== this.appStateService.project.version).length;
                            // If inPCount is 0, stop polling and resolve the promise
                            if (inPCount === 0) {
                                resolve(true);
                                stopPolling();
                                return false;
                            }
                            return true; // Keep polling if versions don't match
                        }),
                        tap(res => {
                            if (res) {
                                // Continue polling only if versions are not correct
                                if (attemptCount < 5) {
                                    attemptCount++;
                                    timer(1000).subscribe(() => poll()); // Delay 1 second before the next attempt
                                } else {
                                    // If we have tried 5 times, log the incorrect invitations and reject the promise
                                    const incorrectInvitations = res.body.filter((invitation: IInvitation) => invitation.regionBenchmarkQuoter &&
                                        `${invitation.majorVersion}.${invitation.minorVersion}.` !== this.appStateService.project.version);
                                    if (incorrectInvitations.length) {
                                        const warningMessage = `After ${attemptCount} attempts, the versions of the following invitations still do not match the project version`;
                                        console.warn(warningMessage, incorrectInvitations);
                                        //this.alertService.warning(warningMessage);
                                    }
                                    resolve(true);
                                    stopPolling();
                                }
                            }
                        })
                    )
                    .subscribe({
                        error: err => {
                            reject(err); // Reject the Promise in case of an error
                            stopPolling();
                        }
                    });
            };

            // Start polling immediately
            poll();
        });
    }

    setDefaults(): void {
        this.dashboardStore.readOnly = this.isSchedulerProjectReadOnlyService.isReadOnly(this.appStateService.project);
        this.dashboardStore.valueColumns = [];
        this.dashboardStore.filterState = this.mainFilterInitialStateStorageService.retrieve(this.appStateService.project.id, 'project_overview_scheduler');
        this.retrieveInfoIfRevertIsPossible();
    }

    init(): Promise<void> {
        return this.appStateService.reloadProject(true).then(() => {
            this.dashboardStore.dashboardMode = null;
            this.dashboardStore.stageIds = null;
            this.dashboardStore.areaIds = null;
            this.dashboardStore.cssElementIds = null;
            return this.reloadAll();
        });
    }

    destroy(): void {
        this._stopInvitationPolling$.next(true);
    }

    addDefaultQuoter(): Observable<number[]> {
        return this.invitationService.addDefaultQuoters(this.appStateService.project.id);
    }

    async updateAllToLatestVersion(): Promise<void> {
        this.dashboardStore.invitations = await lastValueFrom(this.invitationService.query(this.appStateService.project.id).pipe(map(res => res.body)));
        const promises = this.getInvitationOfNotLatestVersion().map((invitation) => {
            return lastValueFrom(this.projectApi.updateQuoterToCurrentProjectVersion(this.appStateService.project.id, invitation.quoterId));
        });
        if (promises.length) {
            return Promise.all(promises).then(() => {
                return;
            });
        }
    }

    retrieveInfoIfRevertIsPossible(): void {
        this.projectApi.isRevertPossible(this.appStateService.project.id).subscribe((res: HttpResponse<boolean>) => {
            this.dashboardStore.isRevertPossible = res.body;
        });
    }

    update(): void {
        this.loadComparisons().then(() => {
            this.updateValueColumns();
        });

        this.updateResourceCost();
    }

    updateResourceCost(): void {
        const actualAreaIds = getActualAreaIds(this.dashboardStore.filterState, this.appStateService.data.scheduleAreaItems);
        const actualStageIds = getActualStageIds(this.dashboardStore.filterState, this.appStateService.data.stageItems);

        if (!actualAreaIds?.length || !actualStageIds?.length) {
            this.dashboardStore.resourceCost = {
                buildingMaterial: 0,
                finishingMaterial: 0,
                labour: 0,
                profit: 0
            }
            return;
        }

        lastValueFrom(this.statQuoterService.resourceCost(
            this.appStateService.project.id,
            this.appStateService.project.defaultQuoter.id,
            actualAreaIds,
            actualStageIds))
            .then((res: HttpResponse<IResourceCost>) => {
                this.dashboardStore.resourceCost = res.body;
            })
    }

    updateDefaultQuoter(quoter: IComparisonQoter): void {
        this.dashboardStore.inProcessUpdatingDefaultQuoter = true;
        this.dashboardStore.valueColumns = null;

        lastValueFrom(this.projectApi
            .updateDefaultQuoter(this.appStateService.project.id, quoter.id))
            .finally(() => {
                this.dashboardStore.inProcessUpdatingDefaultQuoter = false;
            })
            .then(() => {
                this.projectApi.find(this.appStateService.project.id)
                    .subscribe((res: HttpResponse<IProject>) => {
                        this.appStateService.project.defaultQuoter = res.body.defaultQuoter;
                        this.update();
                    });
            });
    }

    isQuoterDefault(quoter: IComparisonQoter): boolean {
        return quoter.id === this.appStateService.project.defaultQuoter?.id;
    }

    toggleItem(item: IMainViewFilterItemItem): void {
        switch (this.dashboardStore.mode) {
            case 'areas':
                if (this.dashboardStore.filterState.areaIds != null) {
                    const items = this.dashboardStore.filterState.areaIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.dashboardStore.filterState.areaIds.length === this.appStateService.data.scheduleAreaItems.length) {
                        this.dashboardStore.filterState.areaIds = null;
                    }
                } else {
                    this.dashboardStore.filterState.areaIds = this.appStateService.data.scheduleAreaItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
            case 'stages':
                if (this.dashboardStore.filterState.stageIds != null) {
                    const items = this.dashboardStore.filterState.stageIds;
                    const index = items.indexOf(item.id);
                    if (index === -1) {
                        items.push(item.id);
                    } else {
                        items.splice(index, 1);
                    }

                    if (this.dashboardStore.filterState.stageIds.length === this.appStateService.data.stageItems.length) {
                        this.dashboardStore.filterState.stageIds = null;
                    }
                } else {
                    this.dashboardStore.filterState.stageIds = this.appStateService.data.stageItems.filter(i => i.id !== item.id).map(i => i.id);
                }
                break;
        }

        this.dashboardStore.filterState = this.dashboardStore.filterState;
    }

    updateItemsIds(): void {
        this.dashboardStore.areaIds = this.dashboardStore.filterState.areaIds ? this.dashboardStore.filterState.areaIds : this.appStateService.data.scheduleAreaItems?.map(i => i.id);
        this.dashboardStore.stageIds = this.dashboardStore.filterState.stageIds ? this.dashboardStore.filterState.stageIds : this.appStateService.data.stageItems?.map(i => i.id);
        this.dashboardStore.cssElementIds = this.dashboardStore.filterState.cssElementIds ? this.dashboardStore.filterState.cssElementIds : this.appStateService.data.cssElementItems?.map(i => i.id);
    }

    updateValueColumns(): void {
        if (!this.dashboardStore.nativeComparison) {
            return;
        }

        const valueColumns: ICostPlanValueColumn[] = [];
        for (let index = 0; index < this.dashboardStore.quoters.length; index++) {
            const quoter = this.dashboardStore.quoters[index];
            const quoterIndex = this.dashboardStore.nativeComparison.quoters.length === 1 ? 0 : this.dashboardStore.nativeComparison.quoters.findIndex(q => q.id === quoter.id);

            const valueColumn: ICostPlanValueColumn = {
                quoter: quoter,
                quoterTotal: 0,
                costPerSqm: 0,
                data: []
            };

            switch (this.dashboardStore.mode) {
                case 'stages':
                    for (const stageItem of (this.appStateService.data.stageItems || [])) {
                        let total = 0;

                        const stage = this.dashboardStore.nativeComparison.stageDTOs.find(stage => stage.id === stageItem.id);
                        if (stage && this.dashboardStore.stageIds.indexOf(stage.id) !== -1) {
                            stage.elementDTOs.forEach(element => {
                                element.taskDTOs.forEach(task => {
                                    if (this.dashboardStore.areaIds.indexOf(task.scheduleAreaRootId) !== -1) {
                                        total += task.totals[quoterIndex];
                                    }
                                })
                            })
                        }

                        valueColumn.data.push({ item: stageItem, total });
                    }
                    break;
                case 'areas':
                    for (const areaItem of (this.appStateService.data.scheduleAreaItems || [])) {
                        let total = 0;

                        this.dashboardStore.nativeComparison.stageDTOs.forEach((stage) => {
                            if (this.dashboardStore.stageIds.indexOf(stage.id) !== -1) {
                                stage.elementDTOs.forEach(element => {
                                    element.taskDTOs.forEach(task => {
                                        if (this.dashboardStore.areaIds.indexOf(task.scheduleAreaRootId) !== -1 && task.scheduleAreaRootId === areaItem.id) {
                                            total += task.totals[quoterIndex];
                                        }
                                    })
                                })
                            }
                        });

                        valueColumn.data.push({ item: areaItem, total });
                    }
                    break;
            }

            valueColumn.quoterTotal = _.sumBy(valueColumn.data, 'total');

            this.scheduleAreasHelperService.calcCostPerSqm(this.dashboardStore.areaIds, valueColumn.quoterTotal).then(res => {
                valueColumn.costPerSqm = res;
            })

            valueColumns.push(valueColumn);
        }
        this.dashboardStore.valueColumns = valueColumns;
        this.dashboardStore.inited = true;
    }

    private loadComparisons(): Promise<void> {
        this.dashboardStore.nativeComparison = {
            quoters: [],
            stageDTOs: []
        };

        const quoterIdsParam = this.dashboardStore.quoters.map(q => q.id);

        if (quoterIdsParam.length === 0) {
            return Promise.resolve();
        }

        this.dashboardStore.inProcessLoadingStatData = true;
        return lastValueFrom(this.projectApi.queryComparison(this.appStateService.project.id, quoterIdsParam))
            .finally(() => {
                this.dashboardStore.inProcessLoadingStatData = false;
            }).then((res: HttpResponse<IComparison>) => {
                this.dashboardStore.nativeComparison = res.body;
            });
    }

    private getInvitationOfNotLatestVersion(): IInvitation[] {
        return this.dashboardStore.invitations.filter(invitation => {
            return !(invitation.quoterId == null || invitation.majorVersion + '.' + invitation.minorVersion == this.appStateService.project.version)
        });
    }

    private getVersionForQuoter(quoterId: number): string | null {
        const invitation = this.dashboardStore.invitations.find(invitation => {
            return invitation.quoterId === quoterId;
        });

        return invitation ? invitation.majorVersion + '.' + invitation.minorVersion : null;
    }

    private startInvitationsLongPolling(): void {
        timer(0, 3000)
            .pipe(concatMap(() => from(this.invitationService.query(this.appStateService.project.id))))
            .pipe(filter((res) => {
                const inPCount = res.body.filter(invitation => invitation.status === 'IN_PROGRESS').length;
                if (inPCount !== this._inProgressStatusCount) {
                    this.dashboardStore.isShowUpdateButtonEnabled = true;
                    this.alertService.warning("Your prices are out of sync, click 'Update Benchmarks' to ensure comparability.", 5000);
                    this._stopInvitationPolling$.next(true);
                    return true;
                }
                return false;
            }))
            .pipe(take(1))
            .pipe(takeUntil(this._stopInvitationPolling$))
            .subscribe();
    }

    private async reloadAll(): Promise<any> {
        const res = await Promise.all([
            lastValueFrom(this.invitationService.query(this.appStateService.project.id)),
            lastValueFrom(this.projectApi.queryQuoters(this.appStateService.project.id)),
        ]);
        this.dashboardStore.invitations = res[0].body;
        this._inProgressStatusCount = this.dashboardStore.invitations.filter(invitation => invitation.status === 'IN_PROGRESS').length;
        if (this._inProgressStatusCount > 0) {
            this.startInvitationsLongPolling();
        }
        this.dashboardStore.quoters = _.uniqBy(res[1].body, 'id')
            .filter(q => this.getVersionForQuoter(q.id) === this.appStateService.project.version)
            .map(q_1 => {
                q_1.selected = (q_1.default && !q_1.company.toLowerCase().startsWith('m'));
                return q_1;
            });
        this.dashboardStore.isShowAverageRatesButtonEnabled = !this.dashboardStore.invitations.find(i => i.regionBenchmarkQuoter === true);
        this.dashboardStore.isUpdateAllToLatestVersionButtonEnabled = this.getInvitationOfNotLatestVersion().length > 0;
        this.updateItemsIds();
        this.update();
    }
}
