import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import toDataUrl from 'app/shared/util/to-data-url';
import { IAccount } from 'app/shared/model/account.model';
import { SERVER_API_URL } from 'app/app.constants';
import { CustomWindow } from 'app/shared/util/custom.window';
import { IActivityInfo } from 'app/shared/model/bp.model';
import { UserApi } from 'app/shared/dataservices/user.api';
import { debounceTime } from 'rxjs/operators';

declare const window: CustomWindow;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userIdentity: IAccount;
    private activityInfo: IActivityInfo;

    private authenticated = false;
    private authSubject = new Subject<IAccount | null>();

    constructor(private http: HttpClient,
                private userService: UserApi) {
    }

    fetch(): Observable<HttpResponse<IAccount>> {
        let url = SERVER_API_URL + 'api/account';
        return this.http.get<IAccount>(url, { observe: 'response' });
    }

    save(account: IAccount): Observable<HttpResponse<IAccount>> {
        return this.http.post<IAccount>(SERVER_API_URL + 'api/account', account, { observe: 'response' });
    }

    doNotShowInviteModel(): Observable<HttpResponse<void>> {
        return this.http.put<void>(SERVER_API_URL + 'api/account/scheduler/do-not-show-invite-model', null, { observe: 'response' });
    }

    authenticate(identity): void {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authSubject.next(this.userIdentity);
    }

    clear(): void {
        this.userIdentity = undefined;
        this.activityInfo = undefined;
        this.authenticated = false;
        this.authSubject.next(null);
    }

    hasAnyAuthority(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (const authority of authorities) {
            if (this.userIdentity.authorities.includes(authority)) {
                return true;
            }
        }

        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then(
            id => {
                return Promise.resolve(id.authorities && id.authorities.includes(authority));
            },
            () => {
                return Promise.resolve(false);
            }
        );
    }

    hasOnlyOneRole(): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        return this.userIdentity.authorities?.length === 1;
    }

    isAdmin(): boolean {
        return this.hasAnyAuthority(['ROLE_ADMIN']);
    }

    isQuoter(): boolean {
        return this.hasAnyAuthority(['ROLE_QUOTER']);
    }

    isQuoterOnly(): boolean {
        return this.hasOnlyOneRole() && this.isQuoter();
    }

    isScheduler(): boolean {
        return this.hasAnyAuthority(['ROLE_SCHEDULER']);
    }

    isHomeowner(): boolean {
        return this.hasAnyAuthority(['ROLE_HOMEOWNER']);
    }

    async getActivityInfo(force?: boolean): Promise<IActivityInfo> {
        if (force) {
            this.activityInfo = undefined;
        }

        if (this.activityInfo) {
            return Promise.resolve(this.activityInfo);
        }

        const res = await lastValueFrom(this.userService.getActivityInfo().pipe(debounceTime(700)));
        this.activityInfo = res.body;
        return this.activityInfo;
    }

    identity(force?: boolean): Promise<IAccount> {
        if (force) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            this.authSubject.next(this.userIdentity);
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return lastValueFrom(this.fetch()).then(response => {
            const account: IAccount = response.body;

            this.userIdentity = null;
            this.authenticated = false;

            if (account) {
                this.userIdentity = account;

                if (window.dataLayer) {
                    window.dataLayer.push({ userId: account.googleAnalyticsId });
                }

                if (this.userIdentity.imageUrl) {
                    // this.userIdentity.imageUrl = './content/173d8a6f3feff40690419d92da6e0f7a.png'; // temp hack
                    toDataUrl(this.userIdentity.imageUrl, (imgBase64: string) => {
                        if (this.userIdentity) {
                            this.userIdentity.imageBase64 = imgBase64;
                        }
                    });
                }

                this.authenticated = true;
            }

            this.authSubject.next(this.userIdentity);
            return this.userIdentity;
        })
            .catch(err => {
                this.userIdentity = null;
                this.authenticated = false;
                this.authSubject.next(this.userIdentity);
                return null;
            });
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity != null;
    }

    authState$(): Observable<IAccount | null> {
        return this.authSubject.asObservable();
    }

    getAccountId(): number {
        return this.isIdentityResolved() ? this.userIdentity.id : null;
    }

    getLinkedQuoters(): string[] {
        return this.isIdentityResolved() ? this.userIdentity.linkedQuoters : [];
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }

    getImageBase64(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageBase64 : null;
    }

    isReplaceBPLogo(): boolean {
        return this.isIdentityResolved() ? this.userIdentity.replaceBPLogo : false;
    }

    isBeta(): boolean {
        return this.isIdentityResolved() ? this.userIdentity.beta : false;
    }

    getCompany(): string {
        return this.isIdentityResolved() ? this.userIdentity.company : null;
    }

    getLogin(): string {
        return this.isIdentityResolved() ? this.userIdentity.login : null;
    }

    getEmail(): string {
        return this.isIdentityResolved() ? this.userIdentity.email : null;
    }

    getFirstName(): string {
        return this.isIdentityResolved() ? this.userIdentity.firstName : null;
    }

    getLastName(): string {
        return this.isIdentityResolved() ? this.userIdentity.lastName : null;
    }

    getFullName(): string {
        return this.isIdentityResolved() ? `${this.userIdentity.firstName} ${this.userIdentity.lastName}` : null;
    }

    getIntroPassed(): boolean {
        return this.isIdentityResolved() ? this.userIdentity.introPassed : false;
    }

    getTrialPeriodEnd(): any {
        return this.isIdentityResolved() ? this.userIdentity.trialPeriodEnd : null;
    }

    hasPaymentProviders(): boolean {
        return this.userIdentity?.hasPaymentProvider;
    }

    doNotShowAgainSendInviteReminder(): boolean {
        return this.userIdentity?.doNotShowAgainSendInviteReminder;
    }

}
