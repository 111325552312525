import { finalize } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { IProject } from 'app/shared/model/project.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IQuoterTaskCost, IScheduleTask } from 'app/shared/model/schedule-task.model';
import { SearchBySelectInputData } from 'app/shared/components/common/search-by-select/search-by-select.component';
import { HttpResponse } from '@angular/common/http';
import { ScheduleTaskApi } from 'app/shared/dataservices/schedule-task.api';
import { ComponentAreaService } from 'app/shared/dataservices/component-area.service';
import { ScheduleAreaApi } from 'app/shared/dataservices/schedule-area.api';
import { IMaterial } from 'app/shared/model/material.model';
import { IActualMaterialComponent } from 'app/shared/model/actual-material-component.model';
import { GetNaComponentAreaService } from 'app/flows/scheduler/services/get-na-component-area.service';
import { IComponentArea } from 'app/shared/model/component-area.model';
import { AddMaterialModalService } from 'app/shared/components/schedule/add-material-modal/add-material-modal.service';
import { Observable, Observer, Subject } from 'rxjs';
import { QuoterDefaultPricesService } from 'app/shared/dataservices/quoter-default-prices.service';
import { IMaterialCategory } from 'app/shared/model/material-category.model';
import {
    FillTaskTotalForScheduleTaskService
} from 'app/flows/scheduler/schedule/services/fill-task-total-for-schedule-task.service';
import { IBuildUp } from 'app/shared/model/bp.model';
import { AddBuildUpModalService } from 'app/shared/components/common/add-build-up-modal/add-build-up-modal.service';
import {
    SelectInputComponent,
    SelectInputData
} from 'app/shared/components/common/select-input/select-input.component';
import { TaskApi } from 'app/shared/dataservices/task.api';
import Swal from "sweetalert2";

@Component({
    selector: 'bp-schedule-task-details',
    templateUrl: './schedule-task-details.component.html',
    styleUrls: ['schedule-task-details.scss']
})
export class ScheduleTaskDetailsComponent implements OnInit {
    protected project: IProject;
    protected scheduleTask: IScheduleTask;
    protected materials: IMaterial[] = [];
    protected routeData: any;

    protected componentAreaSearchBySelectInputData: SearchBySelectInputData;
    protected buildUpSelectInputData: SelectInputData;
    protected primeMaterialSelectInputData: SelectInputData;

    protected inProcessDeleteScheduleTask = false;
    protected inProcessSaveScheduleTask = false;

    protected provisionalCostWasUpdatedByUser = false;

    protected taskTotal: Observable<number>

    private taskTotalSubject: Subject<number> = new Subject<number>();
    protected taskTotal$ = this.taskTotalSubject.asObservable();

    @ViewChild('buildUpSelectInput')
    private buildUpSelectInput: SelectInputComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private scheduleTaskApi: ScheduleTaskApi,
        private scheduleAreaApi: ScheduleAreaApi,
        private componentAreaService: ComponentAreaService,
        private getNaComponentAreaService: GetNaComponentAreaService,
        private addMaterialModalService: AddMaterialModalService,
        private router: Router,
        private alertService: BpAlertService,
        private taskApi: TaskApi,
        private quoterDefaultPricesService: QuoterDefaultPricesService,
        private fillTaskTotalForScheduleTaskService: FillTaskTotalForScheduleTaskService,
        private addBuildUpModalService: AddBuildUpModalService
    ) {
        this.routeData = this.activatedRoute.data.subscribe(data => {
            this.project = data.project;
            this.scheduleTask = data.scheduleTask;
            this.provisionalCostWasUpdatedByUser = false;

            this.updateTaskTotal();
        });
    }

    private get materialCategory(): IMaterialCategory | null {
        return this.scheduleTask?.materialCategories?.length ? this.scheduleTask.materialCategories[0] : null;
    }

    ngOnInit(): void {
        if (this.materialCategory?.id != null) {
            this.loadMaterials().subscribe(() => {
                this.fillPrimeMaterialSelectInputData();
            });
        }

        this.scheduleTask._defaultUnitValue = this.scheduleTask.ratio ? this.scheduleTask.unitValue / this.scheduleTask.ratio : 0;

        this.fillComponentAreaSearchBySelectInputData();
        this.fillBuildUpsSearchBySelectInputData();
    }

    protected onDeleteTaskClick(): void {
        this.inProcessDeleteScheduleTask = true;

        this.scheduleTaskApi
            .delete(this.scheduleTask.id, this.project.id)
            .pipe(
                finalize(() => {
                    this.inProcessDeleteScheduleTask = false;
                })
            )
            .subscribe(
                () => {
                    this.alertService.success('Schedule task was successfully removed');
                    this.navigateToScheduleScreen();
                }
            );
    }

    protected onSaveTaskClick(): void {
        this.inProcessSaveScheduleTask = true;

        this.scheduleTaskApi
            .update(this.scheduleTask)
            .pipe(
                finalize(() => {
                    this.inProcessSaveScheduleTask = false;
                })
            )
            .subscribe(
                (res: HttpResponse<IScheduleTask>) => {
                    // in general next line does not make sense if we navigate to schedule screen after saving.
                    // but leave this for consistence
                    this.scheduleTask = res.body;
                    //this.alertService.success('Save successful');
                    this.navigateToScheduleScreen();
                }
            );
    }

    protected inProcess(): boolean {
        return this.inProcessDeleteScheduleTask || this.inProcessSaveScheduleTask;
    }

    protected onRatioChange(): void {
        this.updateTaskTotal();
    }

    protected onQtiChange(value: any): void {
        this.scheduleTask.unitValue = value / this.scheduleTask.ratio;

        this.getNaComponentAreaService.get().subscribe((naComponentArea: IComponentArea) => {
            if (this.scheduleTask.componentArea === naComponentArea.componentArea) {
                return;
            }

            this.scheduleTask.componentArea = naComponentArea.componentArea;
            this.scheduleTask.componentAreaId = naComponentArea.id;

            this.componentAreaSearchBySelectInputData.initValue = this.scheduleTask.componentArea;
            this.componentAreaSearchBySelectInputData.initIndex = this.scheduleTask.componentAreaId;

            this.componentAreaSearchBySelectInputData.eventEmitter.next();
        });
    }

    protected onBuildUpSelectionChange(event: IBuildUp | undefined): void {
        this.scheduleTask.buildUp = event.name;
        this.scheduleTask.buildUpId = event ? event.id : null;
    }

    protected onComponentAreaSelectionChange(event): void {
        if (this.scheduleTask.componentAreaId === event.id) {
            return;
        }

        this.scheduleTask.componentArea = event ? event.componentArea : null;
        this.scheduleTask.componentAreaId = event ? event.id : null;

        this.getNaComponentAreaService.get().subscribe((naComponentArea: IComponentArea) => {
            if (this.scheduleTask.componentArea !== naComponentArea.componentArea) {
                this.scheduleAreaApi
                    .findDefaultUnitValue(this.scheduleTask.scheduleAreaId, this.scheduleTask.componentAreaId)
                    .subscribe(
                        (res: HttpResponse<number>) => {
                            this.scheduleTask._defaultUnitValue = +res.body;
                            this.scheduleTask.unitValue = this.scheduleTask._defaultUnitValue * this.scheduleTask.ratio;
                            this.scheduleTask.unit = event.unit.unit;
                            this.scheduleTask.unitId = event.unit.id;
                            this.scheduleTask.unitPlural = event.unit.plural;
                            this.updateTaskTotal();
                        }
                    );
            }
        });
    }

    protected onMaterialSelectionChange(event, actualMaterialComponent: IActualMaterialComponent): void {
        actualMaterialComponent.materialId =
            event.id === actualMaterialComponent.materialId ? actualMaterialComponent.materialId : event.id;
        actualMaterialComponent.referenceUrl = event.referenceUrl;

        this.updateTaskTotal();
    }

    protected onIsSchedulerProvisionalChange(): void {
        if (this.scheduleTask.isSchedulerProvisional) {
            this.scheduleTask.isSchedulerSubcontract = false;
            this.quoterDefaultPricesService
                .queryTaskCost(
                    this.project.defaultQuoter.id,
                    this.scheduleTask.taskId,
                    this.scheduleTask.ratio,
                    this.scheduleTask.unitValue,
                    this.scheduleTask.primeMaterialId,
                    this.scheduleTask.id,
                    this.scheduleTask.clientSupplied,
                    this.scheduleTask.actualMaterialComponents?.reduce((total, actualMaterialComponent) => actualMaterialComponent.cost + total, 0)
                )
                .subscribe((res: HttpResponse<IQuoterTaskCost>) => {
                    this.scheduleTask.provisionalCost = res.body.total;
                    this.updateTaskTotal();
                });
        } else {
            this.scheduleTask.provisionalCost = null;
            this.scheduleTask.actualMaterialComponents.forEach((amc) => delete amc.cost);
            this.scheduleTask.actualLabourComponents.forEach((amc) => delete amc.cost);
            this.updateTaskTotal();
        }
    }

    protected onIsSchedulerSubcontractChange(): void {
        if (this.scheduleTask.isSchedulerSubcontract) {
            this.scheduleTask.isSchedulerProvisional = false;
        }

        this.updateTaskTotal();
    }

    protected removeActualMaterialComponent(event, actualMaterialComponent: IActualMaterialComponent): void {
        event.preventDefault();
        this.scheduleTask.primeMaterialId = -1;
        const index = this.scheduleTask.actualMaterialComponents.indexOf(actualMaterialComponent);
        this.scheduleTask.actualMaterialComponents.splice(index, 1);
    }

    protected onSubComponentProvisionalCostChange(): void {
        this.scheduleTask.actualLabourComponents.forEach((alc) => {
            alc.cost = +alc.cost;
        })

        this.scheduleTask.actualMaterialComponents.forEach((alc) => {
            alc.cost = +alc.cost;
        })

        const laborCost = this.scheduleTask.actualLabourComponents.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0);
        const materialCost = this.scheduleTask.actualMaterialComponents.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0);

        this.scheduleTask.provisionalCost = laborCost + materialCost;

        this.updateTaskTotal();
    }

    protected updateTaskTotal(): void {
        if (!this.scheduleTask.taskTotal || (!this.scheduleTask.isSchedulerProvisional && !this.scheduleTask.isSchedulerSubcontract)) {
            this.fillTaskTotalForScheduleTaskService
                .fill(this.scheduleTask, this.getDefaultQuoterId())
                .then((quoterTaskCost: IQuoterTaskCost) => {
                    this.scheduleTask.taskTotal = quoterTaskCost;
                    this.taskTotalSubject.next(this.scheduleTask.taskTotal.total);
                });
        } else {
            if (this.scheduleTask.isSchedulerProvisional) {
                this.scheduleTask.taskTotal.total = this.scheduleTask.provisionalCost;
                this.taskTotalSubject.next(this.scheduleTask.taskTotal.total);
            } else if (this.scheduleTask.isSchedulerSubcontract) {
                this.scheduleTask.taskTotal.total = this.scheduleTask.unitValue *
                    (this.scheduleTask.actualLabourComponents.reduce((accumulator, currentValue) => accumulator + currentValue.subcontractCost, 0) +
                        this.scheduleTask.actualMaterialComponents.reduce((accumulator, currentValue) => accumulator + currentValue.subcontractCost, 0));
                this.taskTotalSubject.next(this.scheduleTask.taskTotal.total);
            }
        }
    }

    protected showDeleteConfirmation(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this task!',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-primary btn-border-radius waves-effect m-r-10',
                cancelButton: 'btn btn-danger btn-border-radius waves-effect'
            },
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel'
        }).then(result => {
            if (result.isConfirmed) {
                // User clicked "Delete"
                this.onDeleteTaskClick(); // Call your existing delete function
            }
        });
    }

    private fillComponentAreaSearchBySelectInputData(): void {
        this.componentAreaSearchBySelectInputData = {
            searchBy: 'componentArea',
            indexProperty: 'id',
            titleProperty: 'componentArea',
            itemService: this.componentAreaService,
            initValue: this.scheduleTask.componentArea,
            initIndex: this.scheduleTask.componentAreaId,
            dropdownPosition: 'bottom',
            eventEmitter: new Subject(),
            pageSize: 100
        };
    }

    private fillBuildUpsSearchBySelectInputData(): void {
        this.buildUpSelectInputData = {
            indexProperty: 'id',
            titleProperty: 'name',
            data: [...this.scheduleTask.buildUps || [], {id: -1, name: "N/A"}],
            initItem: this.scheduleTask.buildUps.find(buildUp => buildUp.id === this.scheduleTask.buildUpId),
            initIndex: this.scheduleTask.buildUpId ?? -1,
            dropdownPosition: 'bottom',
            onEnterClickHandler: this.onBuildUpSelectInputEnterClick.bind(this)
        };
    }

    private fillPrimeMaterialSelectInputData(): void {
        const primeActualMaterialComponent = this.scheduleTask.actualMaterialComponents?.find(actualMaterialComponent => actualMaterialComponent.isPrime);
        if (primeActualMaterialComponent) {
            this.primeMaterialSelectInputData = {
                indexProperty: 'id',
                titleProperty: 'material',
                initIndex: primeActualMaterialComponent.materialId,
                data: this.materials,
                dropdownPosition: 'bottom',
                onEnterClickHandler: this.onMaterialSelectInputEnterClick.bind(this, primeActualMaterialComponent)
            };
        }
    }

    private loadMaterials(): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            this.taskApi.materials(this.project.id, this.scheduleTask.taskId).subscribe(
                (res: HttpResponse<IMaterial[]>) => {
                    this.materials = res.body;

                    observer.next();
                    observer.complete();
                }
            );
        });
    }

    private onMaterialSelectInputEnterClick(actualMaterialComponent: IActualMaterialComponent, currentTextValue: string): void {
        if (!currentTextValue.length) {
            return;
        }
        const modalRef = this.addMaterialModalService.open(
            this.project,
            this.scheduleTask,
            currentTextValue,
            this.materialCategory?.id,
            this.scheduleTask.unit
        );
        modalRef.result.then(
            (createdMaterial: IMaterial) => {
                if (actualMaterialComponent.isPrime) {
                    this.scheduleTask.primeMaterialId = createdMaterial.id;
                    this.updateTaskTotal();
                }

                actualMaterialComponent.referenceUrl = createdMaterial.referenceUrl;
                actualMaterialComponent.materialId = createdMaterial.id;
                actualMaterialComponent.clientSupplied = !this.scheduleTask.primeMaterialCostAvailable;


                this.loadMaterials().subscribe(() => {
                    this.fillPrimeMaterialSelectInputData();

                    this.primeMaterialSelectInputData.initItem = createdMaterial.material;
                    this.primeMaterialSelectInputData.initIndex = createdMaterial.id;
                });
            },
            reason => {
                // This is intentional
            }
        );
    }

    private navigateToScheduleScreen(): void {
        this.router.navigate(['../../../schedule', this.project.id], { relativeTo: this.activatedRoute });
    }

    private getDefaultQuoterId(): number | null {
        return this.project.defaultQuoter != null ? this.project.defaultQuoter.id : null;
    }

    private onBuildUpSelectInputEnterClick(currentTextValue: string): void {
        if (currentTextValue.length <= 0) {
            return;
        }

        this.addBuildUpModalService.open(currentTextValue).result.then(
            (result: IBuildUp) => {
                if (result == null) {
                    return;
                }

                this.scheduleTask.buildUps = this.scheduleTask.buildUps || [];
                this.scheduleTask.buildUps.push(result);

                this.buildUpSelectInputData.initItem = result;
                this.buildUpSelectInputData.initIndex = result.id;

                this.onBuildUpSelectionChange(result);
                this.buildUpSelectInput.refresh();
            },
            reason => {
                // This is intentional
            }
        );
    }
}
