import { finalize } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from 'app/shared/dataservices/invitation.service';
import Swal from 'sweetalert2';
import { ActivateService } from 'app/shared/dataservices/activate.service';
import { MainBpBackgroundService } from 'app/shared/services/main-bp-background.service';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { LoginService } from 'app/core/login/login.service';
import { GoogleApi } from 'app/shared/services/google.api';
import { Subscription } from 'rxjs';

const TOKEN_PARAM = 'token';
const ACTIVATION_KEY_PARAM = 'key';

@Component({
    selector: 'bp-login',
    templateUrl: './login.component.html',
    styleUrls: ['login.scss']
})
export class BpLoginComponent implements AfterViewInit, OnInit, OnDestroy {
    incorrectCredentialsError: boolean;
    httpError: string;
    password: string;
    username: string;
    credentials: any;
    token: string;
    routeQuerySub = Subscription.EMPTY;

    protected submitted = false;

    constructor(
        private loginService: LoginService,
        private activateService: ActivateService,
        private invitationService: InvitationService,
        private stateStorageService: StateStorageService,
        private elementRef: ElementRef,
        private router: Router,
        private route: ActivatedRoute,
        private mainBpBackgroundService: MainBpBackgroundService,
        private googleApi: GoogleApi
    ) {
        this.credentials = {};
        this.routeQuerySub = this.route.queryParams.subscribe(params => {
            this.token = params[TOKEN_PARAM];

            const activationKey = params[ACTIVATION_KEY_PARAM];

            if (activationKey != null) {
                this.activateService.get(activationKey).subscribe(
                    () => {
                        Swal.fire({
                            title: 'Your email address has been confirmed',
                            toast: true,
                            position: 'top',
                            background: 'lightgray',
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    },
                    () => {
                        this.httpError = 'Your user could not be activated. Please use the registration form to sign up.';
                    }
                );
            }
        });
    }

    ngOnInit(): void {
        this.mainBpBackgroundService.activate();
    }

    ngOnDestroy(): void {
        this.routeQuerySub.unsubscribe();
        this.mainBpBackgroundService.deactivate();
        Swal.close();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.querySelector('#username').focus();
        }, 0);
    }

    login(): void {
        this.submitted = true;

        if (!this.username?.length || !this.password?.length) {
            return
        }

        this.incorrectCredentialsError = false;
        this.httpError = null;

        this.loginService
            .login({
                username: this.username,
                password: this.password,
                rememberMe: true
            })
            .then(() => {
                const processLogin = () => {
                    if (
                        this.router.url.startsWith('/login') ||
                        this.router.url.startsWith('/welcome-login') ||
                        this.router.url.startsWith('/sign-up') ||
                        this.router.url.startsWith('/create-quoter-from-invitation') ||
                        this.router.url.startsWith('/register') ||
                        /^\/activate\//.test(this.router.url) ||
                        /^\/reset\//.test(this.router.url)
                    ) {
                        this.router.navigate(['']);
                    } else {
                        // TODO: is this used?
                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is successful, go to stored previousState and clear previousState
                        const redirect = this.stateStorageService.getUrl();
                        if (redirect) {
                            this.stateStorageService.storeUrl(null);
                            this.router.navigate([redirect]);
                        }
                    }
                };

                if (this.token) {
                    // todo
                    this.invitationService
                        .acceptInvitation(this.token)
                        .pipe(
                            finalize(() => {
                                processLogin();
                            })
                        )
                        .subscribe();
                } else {
                    processLogin();
                }
            })
            .catch((event: any) => {
                this.httpError = event?.error?.detail || `Apologies, we’re just making an update to the system, it will be back online in a minute or two.`;
                this.incorrectCredentialsError = this.httpError === 'Bad credentials';
            });
    }

    requestResetPassword(): void {
        this.router.navigate(['/reset', 'request']);
    }

    signInWithGoogle(): void {
        this.googleApi.authInWithGoogle(false);
    }

    singUp(): void {
        /*
         * https://buildpartner.atlassian.net/browse/BP-1885
         *
         *  window.location.href = 'https://buildpartner.com/#wpcf7-f529-o1';
         * */
        this.router.navigate(['/sign-up']);
    }

}
