export const environment = {
    production: true,
    env: 'prod',
    server_api_url: 'https://prod-api.buildpartner.com/',
    gocardles_script: 'https://dropin.gocardless.com/script.js',
    public_stripe_api_key: 'pk_live_51Gyf9WIpV58FRykG8cVkmczi0yHLmct0WtzaKFvc819zfRovVN1mMLiKXxf4doYC9Ll6H4ZRog3MgCRAp5mutjxV00LH5aWlin',
    server_build_version: '5952',
    pdf_monkey_url: 'https://api.pdfmonkey.io/',
    pdf_monkey_template_id: 'bt8-bpsMFroxzcxKs7NP',
    pdf_monkey_document_template_id: 'EBA7D5FC-80D7-412C-B8CC-459E969F74D5',
    stripe_promotion_code: 'promo_1PhujvIpV58FRykGt6PQ2peu',
    stripe_price_id: 'price_1Mh0xxIpV58FRykGxda3eVS6',
    systemUpgrades: false
};
