import { Injectable } from '@angular/core';
import { FreemiumModalService } from 'app/shared/components/common/freemium-modal/freemium-modal.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AddNewProjectService {

    constructor(
        private freemiumModalService: FreemiumModalService,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
    }

    public addNew(): void {
        this.freemiumModalService.verify('create_project').then((allowed) => {
            if (allowed) {
                this.router.navigate(['../new-project'], { relativeTo: this.activatedRoute });
                /*this.howToCreateProjectModalService.open().result.then((res: {
                    type: HowToCreateProjectType
                }) => {
                    switch (res.type) {
                        case 'cost-plan-wizard':
                            this.appStateService.resetProject();
                            this.router.navigate(['../new-project'], { relativeTo: this.activatedRoute });
                            break;
                        case 'bp-estimating-service':
                            this.estimatingAddonService.queryAllServices().then(addons => {
                                this.serviceSelectorModalService.open(addons).result.then(
                                    (res: { addon: IEstimatingAddon }) => {
                                        this.appStateService.resetProject();
                                        this.router.navigate(
                                            ['../new-project'],
                                            { queryParams: { addonId: res.addon.id }, relativeTo: this.activatedRoute }
                                        )
                                    })
                            })
                            break;
                    }
                })*/
            }
        })
    }
}
