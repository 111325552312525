import { Component, OnDestroy } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { DashboardService } from 'app/flows/scheduler/dashboard/services/dashboard.service';
import { Subscription } from 'rxjs';
import { skip } from "rxjs/operators";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ApplicationStateService } from "app/core/application-state.service";

/**
 * Dashboard
 */
@Component({
    selector: 'bp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['dashboard.scss']
})
export class DashboardComponent implements OnDestroy {
    @BlockUI() blockUI: NgBlockUI;

    private _filterStateSub = Subscription.EMPTY;
    private _currentItemSub = Subscription.EMPTY;

    constructor(
        private _appState: ApplicationStateService,
        protected dashboardStore: DashboardStore,
        protected dashboardService: DashboardService
    ) {
        this._currentItemSub = this._appState.currentItem$
            .pipe(skip(1))
            .subscribe(item => {
                this.dashboardStore.dashboardMode = null;
            });

        this._appState.project = this._appState.project;

        this.blockUI.start("Loading dashboard...");

        this.dashboardService.checkInvitations().then(() => {
            dashboardService.setDefaults();
            dashboardService.init().then(() => {
                this._filterStateSub = this.dashboardStore.filterState$.subscribe(() => {
                    this.dashboardService.updateItemsIds();
                    this.dashboardService.updateValueColumns();
                    this.dashboardService.updateResourceCost();
                })
            }).finally(() => {
                this.blockUI.stop();
            });

        });
    }

    ngOnDestroy(): void {
        this.dashboardService.destroy();
        this._filterStateSub.unsubscribe();
        this._currentItemSub.unsubscribe();
    }

}
