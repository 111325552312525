import { Moment } from 'moment';
import { ISpecification } from 'app/shared/model/specification.model';
import { ITag } from 'app/shared/model/tag.model';
import { IQuoter } from 'app/shared/model/quoter.model';
import { IRegion } from 'app/shared/model/region.model';
import { ProjectMemberRole } from 'app/shared/model/project-team-member.model';
import { Role } from 'app/shared/model/common.model';
import { IEstimatingAddon } from "app/shared/model/bp.model";

export type ProjectStatus =
    | 'Draft'
    | 'Cost Plan'
    | 'Schedule'
    | 'Quoting'
    | 'Tendering'
    | 'Awaiting Decision'
    | 'Completed'
    | 'Variations Made'
    | 'Quote Accepted';

export interface IProject {
    id?: number;
    currentUserRelation?: Role[]
    address?: string;
    addressSecondLine?: string;
    description?: string;
    clientName?: string;
    longitude?: number;
    latitude?: number;
    createdDate?: Moment;
    startDate?: Moment;
    endDate?: Moment;
    city?: string;
    postcode?: string;
    owner?: string;
    email?: string;
    phone?: string;
    status?: ProjectStatus;
    specification?: ISpecification;
    attachmentsUrl?: string;
    tags?: ITag[];
    notes?: string;
    defaultQuoter?: IQuoter;
    version?: string;
    icon?: string;
    templateName?: string;
    archive?: boolean;
    previousVersionAvailable?: boolean;
    region?: IRegion;

    maxCost?: number;
    minCost?: number;

    builderCost?: number;

    schedulerCompany?: string;
    schedulerFirstName?: string;
    schedulerLastName?: string;
    schedulerMail?: string;

    tenderDeadline?: Moment;
    tenderDecision?: Moment;
    tenderNotes?: string;

    isOwner?: boolean;
    privilege?: ProjectMemberRole;
    qsReviewed?: boolean;
    isPaid?: boolean;
    isDefault?: boolean;

    estimatingAddOn?: IEstimatingAddon[];

    scheduleLastModifiedDate?: any;
    timestamp?: number;

    viewedRates?: boolean;
}

export interface IProjectCache extends IProject {
}

export class Project implements IProject {
    constructor(
        public id?: number,
        public address?: string,
        public addressSecondLine?: string,
        public description?: string,
        public clientName?: string,
        public longitude?: number,
        public latitude?: number,
        public startDate?: Moment,
        public endDate?: Moment,
        public city?: string,
        public postcode?: string,
        public owner?: string,
        public email?: string,
        public phone?: string,
        public status?: ProjectStatus,
        public specification?: ISpecification,
        public tags?: ITag[],
        public notes?: string,
        public defaultQuoter?: IQuoter,
        public version?: string,
        public icon?: string,
        public templateName?: string,
        public archive?: boolean,
        public previousVersionAvailable?: boolean,
        public region?: IRegion,
        public maxCost?: number,
        public minCost?: number,
        public builderCost?: number
    ) {}
}
