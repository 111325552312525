import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IEstimatingAddon } from 'app/shared/model/bp.model';

const currencyMap = {
    '£': 'GBP',
    '$': 'USD',
    '€': 'EUR',
    '₽': 'RUB',
    '¥': 'JPY',
    '₹': 'INR'
};

@Component({
    selector: 'bp-service-selector-modal-modal',
    templateUrl: './service-selector-modal.component.html',
    styleUrls: ['service-selector-modal.scss']
})
export class ServiceSelectorModalComponent {

    protected _addons: IEstimatingAddon[] = [];
    protected _selectedAddon: IEstimatingAddon | null = null;

    constructor(private activeModal: NgbActiveModal) {
    }

    protected currencyCode(addon: IEstimatingAddon): string {
        return currencyMap[addon.priceUnit] || 'GBP';
    }

    protected close(): void {
        this.activeModal.dismiss('close');
    }

    protected select(addon: IEstimatingAddon): void {
        this._selectedAddon = addon;
    }

    protected ok(): void {
        this.activeModal.close({ addon: this._selectedAddon });
    }
}
