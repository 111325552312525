<div *ngIf="context.suggestedQuoters$ | async as quoters">
    <div class="flex flex-column gap-20">
        <h3>Suggested in your area:</h3>
        <div *ngIf="quoters.length; else noSuggestions" class="suggestions">
            <div *ngFor="let quoter of quoters" class="suggestion">
                <div class="flex flex-column gap-10">
                    <h3 class="overflow-ellipsis"
                        [title]="quoter.company || quoter.email">{{ quoter.company || quoter.email }}</h3>
                    <div class="overflow-ellipsis">{{ quoter.firstName ?? " " }} {{ quoter.lastName ?? "" }}</div>
                    <div *ngIf="quoter.phoneNumber" class="overflow-ellipsis">
                        Tel:
                        <a [href]="'tel:' + quoter.phoneNumber" class="phone-link">
                            {{ quoter.phoneNumber }}
                        </a>
                    </div>
                </div>
                <div class="flex justify-content-center align-items-center">
                    <img [src]="quoter.logo ?? '/content/images/builder.png'" style="width: 100%">
                </div>
                <div class="flex justify-content-between">
                    <button [disabled]="!quoter.website"
                            (click)="view(quoter)"
                            aria-hidden="true"
                            type="button"
                            class="btn btn-secondary btn-outline-info waves-effect">
                        View
                    </button>
                    <button (click)="invite(quoter)"
                            aria-hidden="true"
                            type="button"
                            class="btn btn-primary waves-effect">
                        Invite
                    </button>
                </div>
            </div>
        </div>

        <ng-template #noSuggestions>
            <div class="text-primary text-center">
                No suggestions
            </div>
        </ng-template>
    </div>
</div>
