import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IScopesHeader } from "app/shared/model/bp.model";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class SubscriptionApi {
    public resourceUrl = SERVER_API_URL + 'api/subscription';

    constructor(private http: HttpClient) {
    }

    pause(userId: number, months: number): Observable<HttpResponse<void>> {
        return this.http.put<void>(`${this.resourceUrl}/pause`, { userId, months }, { observe: 'response' });
    }

    levels(): Observable<string[]> {
        return this.http.get<{ subscriptionLevels: string[] }>(`${this.resourceUrl}/levels`)
            .pipe(
                map((res) => res.subscriptionLevels)
            );
    }
}
