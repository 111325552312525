import { Component, Input, OnInit } from '@angular/core';
import { DashboardStore } from 'app/flows/scheduler/dashboard/stores/dashboard.store';
import { DashboardViewMode } from 'app/flows/scheduler/dashboard/dashboard.interfaces';

@Component({
    selector: 'bp-dashboard-tile',
    templateUrl: './tile.component.html',
    styleUrls: ['tile.scss']
})
export class DashboardTileComponent implements OnInit {

    @Input() title!: string;
    @Input() mode: DashboardViewMode;
    @Input() showViewButton: boolean = true;

    constructor(
        protected dashboardStore: DashboardStore) {
    }

    ngOnInit(): void {
    }

    onViewClick(e: any): void {
        if (!this.showViewButton) {
            return;
        }

        window.scrollTo(0, 0);
        this.dashboardStore.dashboardMode = this.mode;
    }
}
