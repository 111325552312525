import { Component, OnDestroy, OnInit } from '@angular/core';
import { IQuoter } from "app/shared/model/quoter.model";
import { QuotesService } from "app/flows/scheduler/quotes/services/quotes.service";
import { AbsoluteUrlPipe } from "app/shared/pipes/absolute-url.pipe";

/**
 * Suggested quoters
 */
@Component({
    selector: 'bp-suggested-quoters',
    templateUrl: './suggested-quoters.component.html',
    styleUrls: ['suggested-quoters.scss']
})
export class SuggestedQuotersComponent implements OnInit, OnDestroy {
    constructor(
        protected context: QuotesService,
        private absoluteUrlPipe: AbsoluteUrlPipe
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    protected view(quoter: IQuoter): void {
        window.open(this.absoluteUrlPipe.transform(quoter.website), '_blank');
    }

    protected invite(quoter: IQuoter): void {
        this.context.newInvitation = quoter;
        this.context.sendInvite();
    }
}
